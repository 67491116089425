<template>
  <div>
    <span>
      <div>
        <table id="my-table">
          <thead>
            <tr>
              <th id="activity" style="align:center;"></th>

              <th style="align:center;"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(obj, key) in report">
              <tr :key="key">
                <td>{{ obj.value }}</td>
                <td>{{ obj.childs[0].value }}</td>
              </tr>
            </template>
            <tr v-if="report.length > 1">
              <td>{{ sumData.value }}</td>
              <td>{{ sumData.childs[0].value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import { ResponsiveDirective } from "vue-responsive-components";
import NoData from "@/components/General/NoData";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  props: {
    report: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      nameRoute: ""
    };
  },
  created() {},

  methods: {
    rowspan(key) {
      if (key % this.report.length == 0) return this.report.length;
      else return 1;
    }
  },
  watch: {},
  computed: {
    sumData() {
      var totalSum = {};
      if (this.report.length > 1) {
        var $this = this;
        var yFrom = 0,
          yTo = 0;
        var yTo = Math.max.apply(
          Math,
          $this.report.map(function(o) {
            return Number(o.value);
          })
        );
        var yFrom = Math.min.apply(
          Math,
          $this.report.map(function(o) {
            return Number(o.value);
          })
        );

        var lastObject = {
          value: yFrom + "-" + yTo,
          name: "reports.year",
          childs: $this.$_.cloneDeep($this.report[0].childs)
        };
        var suma = 0;
        for (let i = 0; i < $this.report.length; i++) {
          suma += Number($this.report[i].childs[0].value);
        }
        lastObject.childs[0].value = suma;
        totalSum = lastObject;
      }
      return totalSum;
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  td {
    width: 50%;
  }
}
</style>
